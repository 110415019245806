import React from "react";
import style from "./CompareTable.module.scss";
import Table from "Components/base/Table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { convertedSecondToTime as formatTime } from "consts/dateTime";
import useWindowSize from "Hooks/useWindowSize";
import { CONSTANTS } from "consts";

interface fieldAPI {
    workouts: number;
    meters: number;
    time: number;
    calories: number;
    outputs: number;
    strokes: number;
    avgCal: number;
    avgSpm: number;
    avg500m: number;
    avgWatt: number;
}
interface compareTableProps {
    summaryTable: {
        different: fieldAPI;
        thisPeriod: fieldAPI;
        lastPeriod: fieldAPI;
    };
    nameHeaderRow: Array<any>;
    selected: any;
}
const CompareTable = ({
    summaryTable,
    nameHeaderRow,
    selected,
}: compareTableProps) => {
    const { different, thisPeriod, lastPeriod } = summaryTable;
    const { valueApi } = selected;
    const windowSize = useWindowSize();
    const EMPTY_VALUE = "---";
    const getLongTextClassName = (value: any, options?: any) => {
        if (value && value !== "") {
            if (options?.isTime) {
                const str = formatTime(Math.abs(value), true).toString();
                if (str.length >= 9) {
                    return "cellLongText";
                } else if (str.length > 11) {
                    return "cellLongText1";
                }
            } else {
                const str = String(value.toFixed(0));
                if (str.length >= 9) {
                    return "cellLongText";
                } else if (str.length > 11) {
                    return "cellLongText1";
                }
            }
        }
        return "";
    };

    const appId = localStorage.getItem("appId");
    const isBikeergMachine = Number(appId) === CONSTANTS.SWITCH_MACHINE.c2Bike.id;

    const columns = [
        { title: "STATS", key: "headerRow" },
        { title: "Workouts", key: "workout" },
        { title: "Time", key: "time" },
        { title: "OUTPUT(KJ)", key: "output" },
        { title: "AVG.WATT", key: "wattAvg" },
        { title: "CALORIES", key: "calories" },
        { title: "METERS", key: "metter" },
        { title: isBikeergMachine ? "REVOLUTIONS" : "STROKES", key: "stroke" },
        { title: "AVG.CALS/HOUR", key: "calsHourAvg" },
        { title: "AVG.SPLIT", key: "min500mAvg" },
        { title: isBikeergMachine ? "AVG.RPM" : "AVG.SPM", key: "spmAvg" },
    ];
    const columnsMobile = [
        { title: "STATS", key: "headerRow" },
        {
            title: nameHeaderRow[0],
            key: "thisPeriod",
            className: "lastDayCell",
        },
        { title: "DIFFERENCE", key: "diffPeriod" },
        {
            title: nameHeaderRow[1],
            key: "lastPeriod",
            className: "previousDayCell",
        },
    ];
    const renderDifferentCell = (value: any, options?: any) => {
        if (value) {
            return (
                <div className={style["diffenrentCell"]}>
                    {value !== 0 && (
                        <div
                            className={
                                value > 0
                                    ? style["upValue"]
                                    : style["downValue"]
                            }
                        >
                            {options?.isTime
                                ? options.hideHours
                                    ? formatTime(Math.abs(value), false)
                                    : formatTime(Math.abs(value), true)
                                : Math.abs(value).toFixed(0)}
                        </div>
                    )}
                    <div className={style["rightIcon"]}>
                        {value > 0 && (
                            <div className={style["upValue"]}>
                                <ArrowDropUpIcon />
                            </div>
                        )}
                        {value < 0 && (
                            <div className={style["downValue"]}>
                                <ArrowDropDownIcon />
                            </div>
                        )}
                        {value === 0 && "--"}
                    </div>
                </div>
            );
        } else {
            return EMPTY_VALUE;
        }
    };
    const rowsData = [
        {
            headerRow: {
                value: nameHeaderRow[0],
                key: "lastWeek",
                className: "header2",
            },
            workout: {
                value: thisPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
            time: {
                value: formatTime(thisPeriod?.time, true) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
            output: {
                value: thisPeriod?.outputs.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
            wattAvg: {
                value: thisPeriod?.avgWatt.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
            calories: {
                value: thisPeriod?.calories.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.calories),
            },
            metter: {
                value: thisPeriod?.meters.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.meters),
            },
            stroke: {
                value: thisPeriod?.strokes.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.strokes),
            },
            calsHourAvg: {
                value: thisPeriod?.avgCal.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avgCal),
            },
            min500mAvg: {
                value: formatTime(thisPeriod?.avg500m) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avg500m),
            },
            spmAvg: {
                value: thisPeriod?.avgSpm.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avgSpm),
            },
        },
        {
            headerRow: {
                value: "DIFFERENCE",
                key: "different",
                className: "header2",
            },
            workout: {
                value: renderDifferentCell(different?.workouts),
                key: "different",
                className: getLongTextClassName(different?.workouts),
            },
            time: {
                value: renderDifferentCell(different?.time, { isTime: true }),
                key: "different",
                className: getLongTextClassName(different?.time, {
                    isTime: true,
                }),
            },
            output: {
                value: renderDifferentCell(different?.outputs || 0),
                key: "different",
                className: getLongTextClassName(different?.outputs),
            },
            wattAvg: {
                value: renderDifferentCell(different?.avgWatt || 0),
                key: "different",
                className: getLongTextClassName(different?.avgWatt),
            },
            calories: {
                value: renderDifferentCell(different?.calories || 0),
                key: "different",
                className: getLongTextClassName(different?.calories),
            },
            metter: {
                value: renderDifferentCell(different?.meters || 0),
                key: "different",
                className: getLongTextClassName(different?.meters),
            },
            stroke: {
                value: renderDifferentCell(different?.strokes || 0),
                key: "different",
                className: getLongTextClassName(different?.strokes),
            },
            calsHourAvg: {
                value: renderDifferentCell(different && different?.avgCal),
                key: "different",
                className: getLongTextClassName(different?.avgCal),
            },
            min500mAvg: {
                value: renderDifferentCell(different && different?.avg500m, {
                    isTime: true,
                    hideHours: true,
                }),
                key: "different",
                className: getLongTextClassName(different?.avg500m, {
                    isTime: true,
                }),
            },
            spmAvg: {
                value: renderDifferentCell(different && different?.avgSpm),
                key: "different",
                className: getLongTextClassName(different?.avgSpm),
            },
        },
        {
            headerRow: {
                value: nameHeaderRow[1],
                key: "thisWeek",
                className: `header2 ${
                    valueApi !== "today" && "headerPreviousDay"
                }`,
            },
            workout: {
                value: lastPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.workouts),
            },
            time: {
                value: formatTime(lastPeriod?.time, true) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.time, {
                    isTime: true,
                }),
            },
            output: {
                value: lastPeriod?.outputs.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.outputs),
            },
            wattAvg: {
                value: lastPeriod?.avgWatt.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avgWatt),
            },
            calories: {
                value: lastPeriod?.calories.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.calories),
            },
            metter: {
                value: lastPeriod?.meters.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.meters),
            },
            stroke: {
                value: lastPeriod?.strokes.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.strokes),
            },
            calsHourAvg: {
                value: lastPeriod?.avgCal.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avgCal),
            },
            min500mAvg: {
                value: formatTime(lastPeriod?.avg500m) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avg500m),
            },
            spmAvg: {
                value: lastPeriod?.avgSpm.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avgSpm),
            },
        },
    ];
    const rowDataMobile = [
        {
            headerRow: {
                value: "WORKOUTS",
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.workouts) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
            lastPeriod: {
                value: lastPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
        },
        {
            headerRow: {
                value: "Time",
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: formatTime(thisPeriod?.time, true) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.time, { isTime: true }),
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
            lastPeriod: {
                value: formatTime(lastPeriod?.time, true) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
        },
        {
            headerRow: {
                value: columns[3].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.outputs?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.outputs) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
            lastPeriod: {
                value: lastPeriod?.outputs?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
        },
        {
            headerRow: {
                value: columns[4].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.avgWatt?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.avgWatt) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
            lastPeriod: {
                value: lastPeriod?.avgWatt?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
        },
        {
            headerRow: {
                value: columns[5].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.calories?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.calories),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.calories) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.calories),
            },
            lastPeriod: {
                value: lastPeriod?.calories?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.calories),
            },
        },
        {
            headerRow: {
                value: columns[6].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.meters?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.meters),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.meters) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.meters),
            },
            lastPeriod: {
                value: lastPeriod?.meters?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.meters),
            },
        },
        {
            headerRow: {
                value: columns[7].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.strokes?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.strokes),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.strokes) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.strokes),
            },
            lastPeriod: {
                value: lastPeriod?.strokes?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.strokes),
            },
        },
        {
            headerRow: {
                value: columns[8].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.avgCal?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avgCal),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.avgCal) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avgCal),
            },
            lastPeriod: {
                value: lastPeriod?.avgCal?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avgCal),
            },
        },
        {
            headerRow: {
                value: columns[9].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: formatTime(thisPeriod?.avg500m) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avg500m, {
                    isTime: true,
                }),
            },
            diffPeriod: {
                value:
                    renderDifferentCell(different?.avg500m, {
                        isTime: true,
                        hideHours: true,
                    }) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avg500m, {
                    isTime: true,
                }),
            },
            lastPeriod: {
                value: formatTime(lastPeriod?.avg500m) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avg500m, {
                    isTime: true,
                }),
            },
        },
        {
            headerRow: {
                value: columns[10].title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.avgSpm?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avgSpm),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.avgSpm) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avgSpm),
            },
            lastPeriod: {
                value: lastPeriod?.avgSpm?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avgSpm),
            },
        },
    ];
    return (
        <div className={style["CompareTableWrapper"]}>
            {windowSize?.width < 1024 && (
                <Table rowsData={rowDataMobile} columns={columnsMobile} />
            )}
            {windowSize?.width >= 1024 && (
                <Table rowsData={rowsData} columns={columns} />
            )}
        </div>
    );
};

export default CompareTable;
