import { CONSTANTS } from 'consts';

const { WORKOUT_QUERY_RANGE, WORKOUT_PARAMETER } = CONSTANTS;

export const timeRangeData = [
  {
    label: "ALL TIME",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL
  },
  {
    label: "LAST 24 HOURS",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_LAST_24H
  },
  {
    label: "LAST 7 DAYS",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL_LAST_7DAYS
  },
  {
    label: "LAST 30 DAYS",
    value: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL_LAST_30DAYS
  },
];

export const chartOptionsData = [
  {
    label: "OUTPUT",
    value: WORKOUT_PARAMETER.kDIndexKiloJoules
  },
  {
    label: "WATTS",
    value: WORKOUT_PARAMETER.kDIndexWatt,
  },
  {
    label: "CALORIES",
    value: WORKOUT_PARAMETER.kDIndexCalories
  },
  {
    label: "CALS/HOUR",
    value: WORKOUT_PARAMETER.kDIndexCalPerHour
  },
  {
    label: "METERS",
    value: WORKOUT_PARAMETER.kDIndexMeters
  },
  {
    label: "SPLIT",
    value: WORKOUT_PARAMETER.kDIndexSecPer500
  },
  {
    label: "SPM",
    value: WORKOUT_PARAMETER.kDIndexSPM
  },
  {
    label: "HEART RATE",
    value: WORKOUT_PARAMETER.kDIndexHeartRate
  },
]


export const mappingChartOption = {
  [WORKOUT_PARAMETER.kDIndexKiloJoules]: "OUTPUT (KJ)",
  [WORKOUT_PARAMETER.kDIndexWatt]: "WATTS",
  [WORKOUT_PARAMETER.kDIndexCalories]: "CALORIES",
  [WORKOUT_PARAMETER.kDIndexCalPerHour]: "CALS/HOUR",
  [WORKOUT_PARAMETER.kDIndexMeters]: "METERS",
  [WORKOUT_PARAMETER.kDIndexSecPer500]: "SPLIT",
  [WORKOUT_PARAMETER.kDIndexSPM]: "SPM",
  [WORKOUT_PARAMETER.kDIndexHeartRate]: "HEART RATE"
};

export const formatWorkoutList = (workoutHistoryData: any) => {
  let workoutFormated: any = [];
  if (workoutHistoryData) {
    workoutHistoryData.map((item: any) => {
      const workoutData = item.workoutData;
      delete item.workoutData;
      const res = {
        ...item,
        ...workoutData,
        totalMeter: workoutData?.totalMeters,
        totalStrokes: workoutData?.strokes,
        avgCalpHour: workoutData?.avgCalPerHour,
        totalOutput: workoutData?.totalOutputs,
        totalWatt: workoutData?.totalWatts,
      }
      delete res.totalMeters;
      delete res.strokes;
      delete res.avgCalPerHour;
      delete res.totalOutputs;
      delete res.totalWatts;
      workoutFormated.push(res);
    })
  } else {
    workoutFormated = null
  }
  return workoutFormated;
}

export const formatWokoutDetail = (data: any) => {
  const workoutData = data?.workoutHistoryData?.workoutData;
  delete data?.workoutHistoryData?.workoutData;
  const res = {
    ...data?.workoutHistoryData,
    ...workoutData,
    totalMeter: workoutData?.totalMeters,
    totalStrokes: workoutData?.strokes,
    avgCalpHour: workoutData?.avgCalPerHour,
    totalOutput: workoutData?.totalOutputs,
    totalWatt: workoutData?.totalWatts,
    heartRates: workoutData?.dataHeartRate
  }
  delete res.totalMeters;
  delete res.strokes;
  delete res.avgCalPerHour;
  delete res.totalOutputs;
  delete res.totalWatts;
  delete res.dataHeartRate;
  return res;
}

export const formatRelaxTimeData = (timeData: Array<number>, valueData: Array<number>) => {
  const timeArr = [...timeData]; // copy timeData
  const valueArr = [...valueData]; // copy valueData
  for (let i = 0; i < timeArr.length; i++) {
    if (timeArr[i + 1] - timeArr[i] > 5 && timeArr[i + 1] - timeArr[i] <= 7) {
      timeArr.splice(i + 1, 0, timeArr[i] + 5)
      valueArr.splice(i + 1, 0, 0);
    }
    else if (timeArr[i + 1] - timeArr[i] > 7) {
      const tplus5 = timeArr[i] + 5;
      const tsub2 = timeArr[i + 1] - 2;
      timeArr.splice(i + 1, 0, tplus5);
      timeArr.splice(i + 2, 0, tsub2);
      valueArr.splice(i + 1, 0, 0)
      valueArr.splice(i + 1, 0, 0)
    }
  }
  return { timeArr, valueArr }
}