const CompareStatisticsWorkoutMapping = (data: any) => {
  const {summary, latestPeriod: thisPeriodData, previousPeriod: lastPeriodData} = data;
//   console.log({adsadsadsa: data})
  const mapPeriod = (period: any) => {
      let res: {
          no: any;
          date: any;
          workouts: any;
          meters: any;
          time: any;
          calories: any;
          outputs: any;
          strokes: any;
      }[] = [];
      period.map((item: any, key: any) => {
          res.push({
              no: item.no || 0,
              date: item.date || 0,
              workouts: item.totalWorkouts || 0,
              meters: item.totalMeters || 0,
              time: item.totalTime || 0,
              calories: item.totalCalories || 0,
              outputs: item.totalOutput || 0,
              strokes: item.totalStrokes || 0,
          });
      });
      return res;
  };
  return {
      thisPeriod: mapPeriod(thisPeriodData),
      lastPeriod: mapPeriod(lastPeriodData),
      summary : {
          different: {
              workouts: summary?.different?.totalWorkouts || 0,
              meters: summary?.different?.totalMeter || 0,
              time: summary?.different?.totalActiveTime || 0,
              calories: summary?.different?.totalCalories || 0,
              outputs: summary?.different?.totalOutput || 0,
              strokes: summary?.different?.totalStrokes || 0,
              avgCal: summary?.different?.avgCalpHour || 0,
              avgSpm: summary?.different?.avgSPM || 0,
              avg500m: summary?.different?.avgSecp500 || 0,
              avgWatt: summary?.different?.avgWatt || 0,
              date: summary?.different?.date || 0,
          },
          thisPeriod: {
              workouts: summary?.latestPeriod?.totalWorkouts || 0,
              meters: summary?.latestPeriod?.totalMeter || 0,
              time: summary?.latestPeriod?.totalActiveTime || 0,
              calories: summary?.latestPeriod?.totalCalories || 0,
              outputs: summary?.latestPeriod?.totalOutput || 0,
              strokes: summary?.latestPeriod?.totalStrokes || 0,
              avgCal: summary?.latestPeriod?.avgCalpHour || 0,
              avgSpm: summary?.latestPeriod?.avgSPM || 0,
              avg500m: summary?.latestPeriod?.avgSecp500 || 0,
              avgWatt: summary?.latestPeriod?.avgWatt || 0,
              date: summary?.latestPeriod?.date || 0,
          },
          lastPeriod: {
              workouts: summary?.previousPeriod?.totalWorkouts || 0,
              meters: summary?.previousPeriod?.totalMeter || 0,
              time: summary?.previousPeriod?.totalActiveTime || 0,
              calories: summary?.previousPeriod?.totalCalories || 0,
              outputs: summary?.previousPeriod?.totalOutput || 0,
              strokes: summary?.previousPeriod?.totalStrokes || 0,
              avgCal: summary?.previousPeriod?.avgCalpHour || 0,
              avgSpm: summary?.previousPeriod?.avgSPM || 0,
              avg500m: summary?.previousPeriod?.avgSecp500 || 0,
              avgWatt: summary?.previousPeriod?.avgWatt || 0,
              date: summary?.previousPeriod?.date || 0,
          },
      }
  };
};

export {
  CompareStatisticsWorkoutMapping
}