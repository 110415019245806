import React from "react";
import Grid from "@mui/material/Grid";
import { Helmet } from "react-helmet";
import Calendart from "Components/WorkoutOverViewPage/Calendart";
import WeekStreakHoc from "Components/WorkoutOverViewPage/WeekStreakHoc";
import WorkoutVerticalChart from "Components/WorkoutOverViewPage/WorkoutVerticalChart";
import { timeRangeData } from "../handleData";
import { useMainHook } from "./main.hook";
import style from "./overviewPage.module.scss";
import StatBoard from "Components/WorkoutOverViewPage/StatBoard";
import useWindowSize from "Hooks/useWindowSize";
import useGameUserInfoHook from "Hooks/GameUserInfoHook/GameUserInfoHook.hook";

const ProfileWorkout = () => {
  const { gameUserInfo } = useGameUserInfoHook();

    const windowSize = useWindowSize();

    const {
        loading,
        currentSelect,
        workoutMetrics,
        workoutDetail,
        handleSelectTimeRange,
    } = useMainHook();

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Workout history | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>

            <div className="rightMainContent">
                <Grid container spacing={"1.714rem"}>
                    <Grid item xs={12}>
                        <WeekStreakHoc gameUserInfo={gameUserInfo} />
                    </Grid>
                    {windowSize?.width > 1250 && (
                        <Grid item xs={12}>
                            <StatBoard
                                timeRangeData={timeRangeData}
                                currentSelect={currentSelect}
                                handleSelectTimeRange={handleSelectTimeRange}
                                loading={loading}
                                workoutMetrics={workoutMetrics}
                                workoutDetail={workoutDetail}
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    justifyContent={{ xs: "space-between", sm: "flex-start" }}
                    spacing={"1.714rem"}
                    mt={"0px"}
                    className="mui-grid-custom"
                >
                    <Grid item md={12} lg={7} sm={12}>
                        <WorkoutVerticalChart />
                    </Grid>
                    <Grid
                        item
                        md={12}
                        lg={5}
                        sm={12}
                        className={style["calendartGridItem"]}
                    >
                        <Calendart />
                    </Grid>
                </Grid>
                {windowSize?.width <= 1250 && (
                    <Grid item xs={12}>
                        <StatBoard
                            timeRangeData={timeRangeData}
                            currentSelect={currentSelect}
                            handleSelectTimeRange={handleSelectTimeRange}
                            loading={loading}
                            workoutMetrics={workoutMetrics}
                            workoutDetail={workoutDetail}
                        />
                    </Grid>
                )}
            </div>
        </div>
    );
};
export default ProfileWorkout;
