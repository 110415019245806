import React, { useEffect, useRef, useState } from "react";
import style from "./CompareChart.module.scss";
import CardSimple from "Components/CardSimple";
import LineChart from "Components/WorkoutComparePeriodPage/CompareChart/LineChart";
import { Grid } from "@mui/material";
import TabsChart from "Components/base/Tabs";
import { MONTH_NAMES } from "consts/monthNames";
import { formatSingleDigit } from "utils/formatSingleDigit";
import { CONSTANTS } from "consts";

const formatDate = (value: any, modeCompare: any, index?: number) => {
    if (value) {
        if (modeCompare === "today") {
            const dateObj = new Date(value);
            if (dateObj.getHours() === 0) {
                return "0";
            }
            return `${formatSingleDigit(
                dateObj.getHours()
            )}:${formatSingleDigit(dateObj.getMinutes())}`;
        } else if (modeCompare === "365day") {
            //@ts-ignore
            return `DAY ${String(index * 7 + 1)}`;
        } else {
            //@ts-ignore
            return `DAY ${String(index + 1)}`;
        }
    }
};

const formateDateTime = (value: any) => {
    const dateNow = new Date(value);
    return `${MONTH_NAMES[dateNow.getMonth()]} ${dateNow.getDate()}`;
};

interface TabItem {
    active: number;
    label: string;
    value: string;
    yData: {
        label: string;
    };
}

const formatDataChart = (
    thisPeriod: any,
    lastPeriod: any,
    peroidOptions: any,
    timezone?: any
) => {
    const modeCompare = peroidOptions?.modeCompare;
    if (thisPeriod && lastPeriod) {
        let dates = [];
        let workouts1 = [];
        let ouputs1 = [];
        let strokes1 = [];
        let meters1 = [];
        let times1 = [];
        let calories1 = [];
        let timeRange1 = [];
        for (let i = 0; i < lastPeriod.length; i++) {
            const item = lastPeriod[i];
            meters1.push(item.meters);
            times1.push(Number(item.time / 60));
            calories1.push(item.calories);
            workouts1.push(item.workouts);
            ouputs1.push(item.outputs);
            strokes1.push(item.strokes);
            if (i === 0) {
                timeRange1.push(formateDateTime(item.date));
            } else if (i === lastPeriod.length - 1) {
                timeRange1.push(formateDateTime(item.date));
            }
        }
        let workouts2 = [];
        let ouputs2 = [];
        let strokes2 = [];
        let meters2 = [];
        let times2 = [];
        let calories2 = [];
        let timeRange2 = [];
        for (let i = 0; i < thisPeriod.length; i++) {
            const item = thisPeriod[i];
            const dateItem = formatDate(item.date, modeCompare, i);
            dates.push(dateItem);
            meters2.push(item.meters);
            times2.push(Number(item.time / 60));
            calories2.push(item.calories);
            workouts2.push(item.workouts);
            ouputs2.push(item.outputs);
            strokes2.push(item.strokes);
            if (i === 0) {
                timeRange2.push(formateDateTime(item.date));
            } else if (i === thisPeriod.length - 1) {
                timeRange2.push(formateDateTime(item.date));
            }
        }
        return {
            dates: dates,
            timeRange: { timeRange1, timeRange2 },
            meters: { data1: meters1, data2: meters2 },
            times: { data1: times1, data2: times2 },
            workouts: { data1: workouts1, data2: workouts2 },
            outputs: { data1: ouputs1, data2: ouputs2 },
            strokes: { data1: strokes1, data2: strokes2 },
            calories: { data1: calories1, data2: calories2 },
        };
    } else {
        return null;
    }
};

const CompareChart = ({ lastPeriod, thisPeriod, peroidOptions }: any) => {
    const [dataChart, setDataChart] = useState();
    const isC2BikerMachine =
        Number(localStorage?.getItem("appId") || 0) ===
        CONSTANTS.SWITCH_MACHINE.c2Bike.id;
    const tabOptions:TabItem[] = [
        {
            active: 0,
            label: "WORKOUTS",
            value: "workouts",
            yData: { label: "WORKOUTS" },
        },
        {
            active: 1,
            label: "TIME",
            value: "times",
            yData: { label: "MINUTES" },
        },
        {
            active: 2,
            label: "OUTPUT (KJ)",
            value: "outputs",
            yData: { label: "KILOJOULES" },
        },
        {
            active: 3,
            label: "CALORIES",
            value: "calories",
            yData: { label: "CALORIES" },
        },
        {
            active: 4,
            label: "METERS",
            value: "meters",
            yData: { label: "METERS" },
        },
        {
            active: 5,
            label: isC2BikerMachine ? "REVOLUTIONS" : "STROKES",
            value: "strokes",
            yData: { label: isC2BikerMachine ? "REVOLUTIONS" : "STROKES" },
        },
    ];
    const [activeTab, setActiveTab] = useState(tabOptions[0]);

    function usePreviousValue(value: any) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }
    const previousTabOptions = usePreviousValue(tabOptions) as unknown as TabItem[];
    useEffect(() => {
        if (previousTabOptions) {
            if (tabOptions[5].label !== previousTabOptions[5]?.label) {
                if (activeTab.active === 5) {
                    setActiveTab({
                        active: 5,
                        label: isC2BikerMachine ? "REVOLUTIONS" : "STROKES",
                        value: "strokes",
                        yData: {
                            label: isC2BikerMachine ? "REVOLUTIONS" : "STROKES",
                        },
                    });
                }
            }
        }
    }, [tabOptions]);

    useEffect(() => {
        if (lastPeriod && thisPeriod) {
            setDataChart(
                //@ts-ignore
                formatDataChart(thisPeriod, lastPeriod, peroidOptions)
            );
        }
    }, [lastPeriod, thisPeriod]);
    const handleChangeTab = (currentTab: any) => {
        setActiveTab(currentTab);
    };
    return (
        <div className={style["CompareChartWrapper"]}>
            <CardSimple
                title={
                    <Grid
                        container
                        justifyContent={"space-between"}
                        alignItems="center"
                    >
                        <Grid item>
                            <div className="titleCard">COMPARISON GRAPH</div>
                        </Grid>
                        <Grid item>
                            <TabsChart
                                options={tabOptions}
                                handleChangeTab={handleChangeTab}
                                isScrollTab={true}
                            />
                        </Grid>
                    </Grid>
                }
            >
                {dataChart && (
                    <LineChart
                        //@ts-ignore
                        data={dataChart[activeTab["value"]]}
                        //@ts-ignore
                        xData={dataChart.dates}
                        //@ts-ignore
                        timeRange={dataChart.timeRange}
                        tickAmount={peroidOptions.label.number}
                        activeTab={activeTab}
                        labelChart={peroidOptions?.label?.nameCompare}
                        yData={activeTab["yData"]}
                        peroidOptions={peroidOptions}
                    />
                )}
            </CardSimple>
        </div>
    );
};

export default CompareChart;
