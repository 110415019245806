import { Box } from "@mui/material";
import React, { useMemo } from "react";
import Parameter from "./Parameter";
import "./PataramerSection.scss";
import isEmpty from "lodash/isEmpty";
import { CONSTANTS } from "consts";

type propsType = {
    workoutDetail: any;
    workoutMetrics: {
        totalWorkouts: number;
        rank: number;
        totalTime: number;
        totalActiveTime: number;
        totalMeter: number;
        totalOutput: number;
        totalWatt: number;
        totalCalories: number;
        totalStrokes: number;
        avgWatt: number;
        avgCalpHour: number;
        avgSecp500: number;
        avgSPM: number;
    };
    isExpand?: boolean;
    loading?:boolean;
};

export const defaulParameters = {
    totalWorkouts: 0,
    rank: 0,
    totalTime: 0,
    totalActiveTime: 0,
    totalMeter: 0,
    totalOutput: 0,
    totalWatt: 0,
    totalCalories: 0,
    totalStrokes: 0,
    avgWatt: 0,
    avgCalpHour: 0,
    avgSecp500: 0,
    avgSPM: 0,
};

const ParameterSection = (props: propsType) => {
    const { workoutDetail = { rank: 0 }, workoutMetrics = defaulParameters, loading } =
        props;

    const appId = localStorage.getItem("appId");
    const isBikeergMachine = Number(appId) === CONSTANTS.SWITCH_MACHINE.c2Bike.id;

    const convertedTime = (time: number, toHour?: boolean) => {
        if (toHour) {
            if (!time) return "00:00:00";
            let hour = Math.floor(time / 3600);
            let minute = Math.floor(time / 60) % 60;
            let second = Math.round(time % 60);
            if (second === 60) {
                minute++;
                second = 0;
            }
            if (minute === 60) {
                hour++;
                minute = 0;
            }
            return [hour, minute, second]
                .map((item) => (item < 10 ? `0${item}` : String(item)))
                .join(":");
        }

        if (!time) return "00:00";
        let minute = Math.floor(time / 60);
        let second = Math.round(time % 60);
        if (second === 60) {
            minute++;
            second = 0;
        }
        const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
        const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
        return `${minuteStr}:${secondStr}`;
    };

    const getTime = useMemo(() => {
        if(loading) return 0
        return workoutDetail.id
            ? workoutDetail.activeTime
            : workoutMetrics.totalActiveTime;
    }, [workoutDetail, workoutMetrics]);

    const handleDisplayScore = () => {
        if(loading) return "--"
        if (workoutDetail.scoreTitle === "SCORE") {
            return workoutDetail?.scoreValue || "--";
        }
        const rank = workoutDetail?.scoreValue.slice(-2);
        const value = workoutDetail?.scoreValue.slice(
            0,
            workoutDetail?.scoreValue.length - 2
        );
        return (
            <p style={{ margin: 0 }}>
                {value || "--"}
                <sup>{rank}</sup>
            </p>
        );
    };

    const getFirstMetric = () => {
        if (isEmpty(workoutDetail)) {
            return {
                title: "WORKOUTS",
                display: workoutMetrics.totalWorkouts || "0",
            };
        }
        return {
            title: workoutDetail?.scoreTitle,
            display: handleDisplayScore(),
        };
    };

    const firstMetric = getFirstMetric();
    return (
        <Box className="parameter-section-component">
            <Box className="parameter-container">
                <Parameter
                    // rankLetter={"2st"}
                    title={firstMetric.title}
                    parameter={firstMetric.display}
                    className={"scoreParam"}
                />
                <Parameter
                    title={"OUTPUT (KJ)"}
                    parameter={Math.round(workoutMetrics.totalOutput || 0)}
                    className={"outputParam"}
                />
                <Parameter
                    title={"CALORIES"}
                    parameter={Math.round(workoutMetrics.totalCalories || 0)}
                    className={"caloriesParam"}
                />
                <Parameter
                    title={"METERS"}
                    parameter={Math.round(workoutMetrics.totalMeter || 0)}
                    className={"metersParam"}
                />
                <Parameter
                    title={isBikeergMachine ? "REVOLUTIONS" : "STROKES"}
                    parameter={Math.round(workoutMetrics.totalStrokes || 0)}
                    className={"strokesParam"}
                />
                <Parameter
                    title={"TIME"}
                    // parameter={convertedTime(workoutDetail.gameId ? workoutMetrics.totalTime : workoutMetrics.totalActiveTime, true)} />
                    parameter={convertedTime(getTime, true)}
                    className={"timeParam"}
                />
                <Parameter
                    title={"AVG. WATT"}
                    parameter={Math.round(workoutMetrics.avgWatt || 0)}
                    className={"avgWattParam"}
                />
                <Parameter
                    title={"AVG. CALS/HOUR"}
                    parameter={Math.round(workoutMetrics.avgCalpHour || 0)}
                    className={"avgCalsParam"}
                />
                <Parameter
                    title={"AVG. SPLIT"}
                    parameter={convertedTime(workoutMetrics.avgSecp500)}
                    className={"avg500MParam"}
                />
                <Parameter
                    title={isBikeergMachine ? "AVG. RPM" : "AVG. SPM"}
                    parameter={Math.round(workoutMetrics.avgSPM || 0)}
                    className={"avgSpmParam"}
                />
            </Box>
        </Box>
    );
};
export default ParameterSection;
