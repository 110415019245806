import { CONSTANTS } from "consts";
import style from "./AppChip.module.scss";
import React from "react";

interface AppChipProps {
    appId: number;
}
const appChipConfig = {
    c2: {
        id: "C2",
        value: "C2",
        color: "#B7BE11",
        background: "rgba(183, 190, 17, 0.1)",
    },
    avironGo: {
        id: "GO",
        value: "GO",
        color: "#E03100",
        background: "rgba(224, 49, 0, 0.1)",
    },
    waterRower: {
        id: "waterRower",
        value: "Water Rower",
        color: "#000000",
        background: "rgba(0, 0, 0, 0.1)",
    },
    avironRower: {
        id: "avironRower",
        value: "Aviron Rower",
        color: "#FF4106",
        background: "rgba(255, 65, 6, 0.1)",
    },
    avironBike: {
        id: "avironBike",
        value: "Aviron Bike",
        color: "#FF4106",
        background: "rgba(255, 65, 6, 0.1)",
    },
    bikeGo: {
        id: "bikeGo",
        value: "Bike Go",
        color: "#FF4106",
        background: "rgba(255, 65, 6, 0.1)",
    },
    default: { id: "default", value: "---", color: "#666", background: "#ccc" },
};
export const getAppItem = (appId: number) => {
    if (appId === CONSTANTS.APP.AVIRON_APK) {
        return appChipConfig.avironRower;
    } else if (appId === CONSTANTS.APP.AVIRON_GO) {
        return appChipConfig.avironGo;
    } else if (appId === CONSTANTS.APP.WATER_ROWER) {
        return appChipConfig.waterRower;
    } else if (appId === CONSTANTS.APP.AVIRON_BIKE) {
        return appChipConfig.avironBike;
    } else if (appId === CONSTANTS.APP.BIKE_GO) {
        return appChipConfig.bikeGo;
    } else if (
        appId === CONSTANTS.APP.C2_BIKEERG ||
        appId === CONSTANTS.APP.C2_ROWER ||
        appId === CONSTANTS.APP.C2_SKIERG
    ) {
        return appChipConfig.c2;
    } else {
        return appChipConfig.default;
    }
};
const AppChip = ({ appId }: AppChipProps) => {
    const modelColor = getAppItem(appId);
    return (
        <div className={style["AppChip"]}>
            <div
                className={style["appChipContent"]}
                style={{
                    color: modelColor?.color,
                    backgroundColor: modelColor?.background,
                    borderColor: modelColor?.color,
                }}
            >
                <div>{modelColor.value}</div>
            </div>
        </div>
    );
};

export default AppChip;
