import { AxiosRequestConfig } from "axios";
import http, { BaseGameServerAPI } from "./base";
export interface IGetMembershipDetailForC2 {
    orgId: string;
    page?: number;
    perPage?: number;
}

interface UpdateUserProfileRequest {
    firstName?: string,
    lastName?: string,
    height?: number,
    weight?: number,
    heightUnit?: number,
    weightUnit?: number,
    dob?: string,
    gender?: number,
    avatarUrl?: string,
    username?: string
}

export class UserAPI extends BaseGameServerAPI {
    constructor() {
        super();
    }
    public async getUserRecord(records: string[]) {
        return await http.post('/get_user_records', {
            ids: records,
        })
    }

    public async getGameUserInfo() {
        return await http.post('/get_user_exp_info')
    }


    public async getAchievementType() {
        return await http.post('/get_user_stats', {
            includes: ["achievement"]
        })

    }

    public async getAchievementList() {
        return await http.post('/get_achievements', {
            includes: ["achievement"]
        })
    }

    async getGameUserProfile() {
        return await http.post('/get_user_profile')
    }

    async updateGameUserProfile(data: UpdateUserProfileRequest) {
        return await http.post('/update_user_profile', data)
    }

    public async getMembershipHistoriesListForC2() {
        return await http.post('/get_memberships')
        // const requestConfig: AxiosRequestConfig = {
        //     url: this.getUrl("get_memberships"),
        //     method: "post",
        //     headers: {
        //         Authorization: `Bearer ${await this.getToken()}`,
        //     },
        // };
        // return await this.exec(requestConfig);
    }

    public async getPlanListForC2() {
        return await http.post('/get_pricing_plans', {
            platformType: 4,
        })
    }

    public async getMembershipDetailForC2({
        orgId,
        page,
        perPage,
    }: IGetMembershipDetailForC2) {
        return await http.post('/get_membership_detail', {
            orgId,
            pagination: {
                page,
                perPage,
            },
        })
        // const requestConfig: AxiosRequestConfig = {
        //     url: this.getUrl("get_membership_detail"),
        //     method: "post",
        //     headers: {
        //         Authorization: `Bearer ${await this.getToken()}`,
        //     },
        //     data: {
        //         orgId,
        //         pagination: {
        //             page,
        //             perPage,
        //         },
        //     },
        // };
        // return await this.exec(requestConfig);
    }
}
