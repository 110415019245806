import React, { FunctionComponent, memo } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import ChartComponent from "react-chartjs-2";
import "chartjs-plugin-annotation";

import { CONSTANTS } from "consts";
import "./workoutAreaChart.scss";
import maxBy from "lodash/maxBy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useWindowSize from "Hooks/useWindowSize";

type PropsType = {
    // For metric select
    handleSelect: (option: number) => void;
    metricOptions: { label: string; value: number }[];
    currentSelect: number | null;
    isStravaConnected?: boolean;
    isLogbookConnected?: boolean;
    isFitbitConnected?: boolean;
    handleSyncToStrava?: () => void;
    handleSyncToLogbook?: () => void;
    handleSyncToFitbit?: () => void;
    chartData: {
        data: { x: number; y: number }[];
        avgData: number | undefined;
        information: {
            chartName: string;
            paraName: string;
            unit: string;
            isStravaSynced: boolean;
            isLogbookSynced: boolean;
            isFitbitSynced: boolean;
            game: {
                id: number;
                name: string;
                type: string;
                time: string;
                level: string;
                category: string;
            };
        };
    };
    workoutDetail: any;
};

const convertedTime = (timeInSec: number) => {
    if (!timeInSec) return "00:00";
    let minute = Math.floor(timeInSec / 60);
    let second = Math.round(timeInSec % 60);
    if (second === 60) {
        minute++;
        second = 0;
    }
    const minuteStr = minute >= 10 ? String(minute) : `0${String(minute)}`;
    const secondStr = second >= 10 ? String(second) : `0${String(second)}`;
    return `${minuteStr}:${secondStr}`;
};

const formatDataChart = (isSplitMode: boolean, data: any) => {
    if (isSplitMode) {
        let res = [];
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            res.push({
                x: item.x,
                y: item.y === 0 ? 0 : 5 - item.y <=0 ? 0 : 5 - item.y,
            });
        }
        return res;
    }
    return data;
};

const WorkoutChart: FunctionComponent<PropsType> = ({
    chartData,
    handleSelect,
    metricOptions,
    currentSelect,
    isStravaConnected = false,
    isLogbookConnected = false,
    isFitbitConnected = false,
    handleSyncToStrava = () => { },
    handleSyncToLogbook = () => { },
    handleSyncToFitbit = () => { },
    workoutDetail,
}) => {
    const { data: dataC, information, avgData: avgDataC } = chartData;
    const windowSize = useWindowSize();
    const isSplitMode =
        currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexSecPer500;
    const isHeartRate = currentSelect === CONSTANTS.WORKOUT_PARAMETER.kDIndexHeartRate;
    const avgData = isHeartRate ? undefined : avgDataC;
    const data = formatDataChart(isSplitMode, dataC);

    //@ts-ignore
    const maxYValue = Math.max(avgData || 0, maxBy(data, "y")?.y || 0);
    // set another line for avgData
    const averageData =
        avgData && data
            ? [
                { x: 0, y: avgData },
                { x: data[data.length - 1].x, y: avgData },
            ]
            : [];

    const appId = localStorage.getItem("appId");
    const isC2App = React.useMemo(() => {
        if (typeof appId === "string") {
            return [CONSTANTS.APP.C2_ROWER, CONSTANTS.APP.C2_SKIERG, CONSTANTS.APP.C2_BIKEERG].indexOf(Number(appId)) !== -1
        }
        return false;
    }, [appId])

    const chartComponentData = (canvas: any) => {
        // create gradient for chart
        const ctx = canvas.getContext("2d");
        // console.log('ctx', ctx)
        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        gradient.addColorStop(1, "rgba(245, 73, 15, 0)");
        gradient.addColorStop(0, "#F57D0F");
        return {
            labels: ["Average", information.paraName],
            datasets: [
                {
                    label: `Average: ${Math.round(avgData || 0)}`,
                    showLine: false,
                    data: [...averageData],
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: 10,
                },
                {
                    label: information.paraName,
                    showLine: true,
                    data: [...data],
                    borderColor: "#F5490F",
                    backgroundColor: gradient,
                    lineTension: 0.1,
                    borderWidth: 2,
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointHitRadius: ctx.canvas.clientHeight,
                },
            ],
        };
    };


    const calculateStepSize = (maxValue: number) => {
        const startRange = 60;
        if (maxValue / startRange < 10) {
            return startRange
        } else {
            return startRange * Math.round((Math.round(maxValue / startRange) / 5))
        }
    }

    const getMaxXTick = () => {
        const max = data[data.length - 1].x;
        return max;
    };

    const getXStepSize = () => {
        const maxXValue = data[data.length - 1].x;
        if (window.innerWidth < 500) {
            if (maxXValue < 1) {
                return undefined;
            }
            if (maxXValue < 2) {
                return 0.5;
            }
            if (maxXValue < 5) {
                return 1;
            }
            if (maxXValue > 300) {
                return calculateStepSize(maxXValue);
            }
            return Math.round(maxXValue / 6);
        }
        if (maxXValue > 300) {
            return calculateStepSize(maxXValue);
        }
        return undefined;
    };

    // const ROW_Y_AMOUNT = 5;

    const getYStepSize = () => {
        if (isSplitMode) {
            return 1;
        } else {
            if (maxYValue <= 5) {
                return 1;
            } else {
                // const tempString = parseInt(String(maxYValue));
                // const lengthValue = String(tempString).length;
                // const maxTemp = 1 * Number(`1e${lengthValue}`);
                // if (maxTemp / 5 > maxYValue) {
                //     return maxTemp / 5 / ROW_Y_AMOUNT;
                // } else if (maxTemp / 4 > maxYValue) {
                //     return maxTemp / 4 / ROW_Y_AMOUNT;
                // } else if (maxTemp / 2 > maxYValue) {
                //     return maxTemp / 2 / ROW_Y_AMOUNT;
                // } else {
                //     return maxTemp / ROW_Y_AMOUNT;
                // }
                if (window.innerWidth < 500) {
                    if (maxYValue < 1) {
                        return 0.2;
                    }
                    if (maxYValue < 2) {
                        return 0.5;
                    }
                    if (maxYValue < 5) {
                        return 1;
                    }
                    return Math.round(maxYValue / 5);
                }
                return undefined;
            }
        }
    };

    const getMaxValueY = () => {
        if (isSplitMode) {
            return 5;
        } else {
            // if (maxYValue <= 5) {
            //     return 5;
            // } else {
            //     const tempString = parseInt(String(maxYValue));
            //     const lengthValue = String(tempString).length;
            //     let maxTemp = 1 * Number(`1e${lengthValue}`);
            //     if (maxTemp / 5 > maxYValue) {
            //         maxTemp = maxTemp / 5;
            //     } else if (maxTemp / 4 > maxYValue) {
            //         maxTemp = maxTemp / 4;
            //     } else if (maxTemp / 2 > maxYValue) {
            //         maxTemp = maxTemp / 2;
            //     }
            //     if(maxTemp - maxYValue < maxTemp/ROW_Y_AMOUNT/2) {
            //         maxTemp = maxTemp + maxTemp/ROW_Y_AMOUNT
            //     } else if (maxTemp - maxYValue > (maxTemp/ROW_Y_AMOUNT) + (maxTemp/ROW_Y_AMOUNT/2)) {
            //         maxTemp = maxTemp - maxTemp/ROW_Y_AMOUNT
            //     }
            //     return maxTemp;
            // }
            return undefined;
        }
    };
    const getAvgLabelValue = () => {
        if (avgData) {
            if (isSplitMode) {
                const avgValue = 5 - (avgData || 0);
                if (avgValue <= 0) {
                    return 0.2;
                } else {
                    return avgValue;
                }
            } else {
                if (maxYValue - avgData <= 2) {
                    return avgData - 2;
                }
                return avgData;
            }
        }
    };
    const options: any = {
        layout: {
            padding: {
              right: 20 , // Set the padding on the right side of the chart
            },
        },
        legend: {
            display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        // Plugin
        annotation: {
            events: ["mouseenter", "mouseover"],
            drawTime: "afterDatasetsDraw",
            annotations: [
                {
                    type: "line",
                    mode: "horizontal",
                    scaleID: avgData ? "y-axis-1" : "y-axis-0",
                    value: getAvgLabelValue(),
                    borderColor: "transparent",
                    label: {
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        fontFamily: "Work Sans",
                        fontSize: 12,
                        fontColor: "#FFFFFF",
                        xPadding: 20,
                        yPadding: 8,
                        cornerRadius: 40,
                        enabled: true,
                        fontStyle: "normal",
                        content:
                            isSplitMode && avgData
                                ? `AVERAGE: ${
                                      Math.abs(avgData) > 0
                                          ? convertedTime(
                                                Math.abs(avgData) * 60
                                            )
                                          : "00:00"
                                  }`
                                : `AVERAGE: ${Math.round(avgData || 0)}`,
                        position: "left",
                        xAdjust: 50,
                    },
                },
                // {
                //     type: "box",
                //     mode: "horizontal",
                //     scaleID: avgData ? "y-axis-1" : "y-axis-0",
                //     borderColor: "transparent",
                //     backgroundColor: "transparent",
                // },
            ],
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        min: 0,
                        // JUST FOR THIS SecPer500!
                        max: getMaxValueY(),
                        callback: (value: any, index: any, values: any) => {
                            if (isSplitMode) {
                                if (value === 5) return 0;
                                if (value === 4) return 1;
                                if (value === 3) return 2;
                                if (value === 2) return 3;
                                if (value === 1) return 4;
                                if (value === 0) return 5;
                            }
                            if (windowSize.width <= 500) {
                                if (value >= 1000) {
                                    return (
                                        (value / 1000)
                                            .toFixed(1)
                                            .replace(".0", "") + "K"
                                    );
                                }
                            }
                            return value;
                        },
                        stepSize: getYStepSize(),
                        fontFamily: "MonoSpec",
                        padding: 5,
                    },
                    scaleLabel: {
                        display: false,
                        labelString: information.unit,
                        fontSize: window.innerWidth < 500 ? 12 : 16,
                    },
                    gridLines: {
                        drawTicks: false,
                    },
                },
            ],

            xAxes: [
                {
                    scaleLabel: {
                        display: true,
                        labelString: "Time",
                        fontSize: window.innerWidth < 500 ? 12 : 16,
                        fontWeight: "600",
                        color: "#939393",
                    },
                    ticks: {
                        callback: (
                            tick: string,
                            index: number,
                            values: any[]
                        ) => {
                            // console.log({ values, tick, index });
                            if (index === values.length - 1) {
                                return null;
                            } else if (
                                Math.floor(values[1] % 60) === 0 &&
                                Math.floor(Number(tick) % 60) === 0 &&
                                Math.floor(values[1]) >= 60
                            ) {
                                return `${Number(tick) / 60}min`;
                            } else if (Number(tick) < 1) {
                                if (Number(tick) === 0) {
                                    return "0S";
                                }
                                return `${Number(tick).toFixed(1)}s`;
                            } else {
                                return `${Number(tick).toFixed(0)}s`;
                            }
                        },
                        min: 0,
                        max: getMaxXTick(),
                        stepSize: getXStepSize(),
                        fontFamily: "MonoSpec",
                        fontSize: 12,
                        padding: 10,
                    },
                    gridLines: {
                        drawTicks: false,
                        display: false,
                    },
                },
            ],
        },
        showAllTooltips: true,
        tooltips: {
            callbacks: {
                label: (context: any) => {
                    // if (context.datasetIndex === 0) {
                    //     if (isSplitMode && avgData) {
                    //         // This is just for display, not a true value
                    //         // 10 - minute and then * 60 => to get the true value
                    //         const sec = Math.abs(avgData * 60);
                    //         return `Average: ${
                    //             Math.abs(avgData) > 0
                    //                 ? convertedTime(sec)
                    //                 : "00:00"
                    //         }`;
                    //     }
                    //     return `Average: ${Math.round(avgData || 0)}`;
                    // }
                    if (context.datasetIndex === 1) {
                        if (isSplitMode) {
                            const sec = Math.abs(5 - context.yLabel) * 60;
                            return ` SPLIT: ${
                                Math.abs(context.yLabel) > 0
                                    ? convertedTime(sec)
                                    : "05:00"
                            }`;
                        }
                        return ` ${information.unit}: ${
                            Math.round(context.yLabel * 100) / 100
                        }`;
                    }
                    return "";
                },
                footer: (contextArr: any) => {
                    const context = contextArr[0]
                    if (context.datasetIndex === 1) {
                        return `     TIME: ${convertedTime(context.xLabel)}`;
                    }
                },
            },
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            footerFontStyle: 'normal',
        },
    };

    const isActive = (item: number) => {
        return currentSelect === item;
    };

    const handleSelectOption = (item: any) => {
        const domTarget = document.getElementById(
            `workout-chart-option-${item.value}`
        );
        if (domTarget && window.innerWidth < 900) {
            domTarget.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });
        }
        handleSelect(item.value);
    };

    const renderLabelButton = (label: string) => {
        const isBikerMachine =
            Number(localStorage.getItem("appId")) ===
            CONSTANTS.SWITCH_MACHINE.c2Bike.id;
        if(isBikerMachine && label==="SPM") {
            return 'RPM'
        }
        return label;
    }
    return (
        <Box className="workout-chart-component">
            <Stack
                direction="row"
                mb="16px"
                mt="16px"
                overflow="auto"
                display="flex"
                justifyContent="space-between"
                className={"stackTitleWrapper"}
            >
                <div className="title-container">
                    <div className="chart-name">
                        {workoutDetail?.name}
                        {/* {information?.game?.name || information.chartName} */}
                    </div>
                    <div className="unitWrapper">
                        {workoutDetail?.tag?.map(
                            (item: string, index: number) => (
                                <div className="unit-name" key={index}>
                                    {item}
                                </div>
                            )
                        )}
                    </div>
                </div>
                {/* <div className="syncButtonWrapper">
                    {isStravaConnected && !information.isStravaSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            onClick={handleSyncToStrava}
                            className="syncStravaButton"
                        >
                            <b>SYNC STRAVA</b>
                        </Button>
                    )}
                    {isStravaConnected && information.isStravaSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            className="syncStravaButton syncStravaButton__synced"
                            startIcon={<CheckCircleIcon />}
                        >
                            <b>STRAVA SYNCED</b>
                        </Button>
                    )}
                    {isC2App && isLogbookConnected && !information.isLogbookSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            onClick={handleSyncToLogbook}
                            className="syncStravaButton"
                        >
                            <b>SYNC LOGBOOK</b>
                        </Button>
                    )}
                    {isC2App && isLogbookConnected && information.isLogbookSynced && (
                        <Button
                            variant="outlined"
                            color={"secondary"}
                            className="syncStravaButton syncStravaButton__synced"
                            startIcon={<CheckCircleIcon />}
                        >
                            <b>LOGBOOK SYNCED</b>
                        </Button>
                    )}
                </div> */}
            </Stack>
            <Stack
                direction="row"
                spacing={{
                    xs: "8px",
                    md: "8px",
                }}
                mb="16px"
                overflow="auto"
                display="flex"
                sx={{
                    flexWrap: {
                        xs: "nowrap",
                        md: "wrap",
                    },
                    justifyContent: {
                        xs: "space-between",
                        md: "start",
                    },
                    paddingBottom: {
                        xs: "4px",
                        md: 0,
                    },
                    "::-webkit-scrollbar": {
                        display: "none",
                    },
                }}
                className={"tabChartButtons"}
            >
                {metricOptions.map((item) => {
                    return (
                        <div
                            key={item.value}
                            id={`workout-chart-option-${item.value}`}
                        >
                            <Button
                                onClick={handleSelectOption.bind(null, item)}
                                variant="outlined"
                                sx={{
                                    borderColor: isActive(item.value)
                                        ? "#161616 !important"
                                        : "#d1d1d1 !important",
                                    color: isActive(item.value)
                                        ? "#161616 !important"
                                        : "rgb(79, 79, 79) !important",
                                    fontWeight: isActive(item.value)
                                        ? 600
                                        : 400,
                                }}
                            >
                                {renderLabelButton(item.label)}
                            </Button>
                        </div>
                    );
                })}
            </Stack>
            <div className="workoutAreaChartWrapper">
                <div className="workout-area-chart-label-y">
                    {information.unit}
                </div>
                <div className="workoutAreaChartContent">
                    <ChartComponent
                        type="scatter"
                        data={chartComponentData}
                        options={options}
                    />
                </div>
            </div>
        </Box>
    );
};
export default memo(WorkoutChart);