import React, { useEffect, useMemo } from "react";
import {
    Box,
    Divider,
    Grid,
    Pagination,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import GoBack from "Components/GoBack";
import PageTitle from "Components/PageTitle";
import { useQueryMembershipDetailForC2 } from "./MembershipDetailForC2.actions";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useQueryPlanListForC2 } from "../Main/MembershipsMain.actions";
import get from "lodash/get";
import moment from "moment";
import { getStatus } from "../Main/MembershipsMain.constant";
import { AvironLoading } from "Components/Icons/AvironLoading";
import ReceiptsForC2 from "./ReceiptForC2";
import CurrencyNumber from "Components/CurrencyNumber";
import { CONSTANTS } from "consts";
import {getAppItem} from 'Components/AppChip'
import { formatAppIds } from "utils/membership";

const MembershipDetailForC2 = () => {
    const history = useHistory()
    const location = useLocation()
    const { handleQueryData } = useQueryMembershipDetailForC2();
    // const { handleQueryDataPlan } = useQueryPlanListForC2();
    const { id } = useParams() as any;
    const [membershipDetail, setMembershipDetail] =
        React.useState<any>(undefined);
    const [planList, setPlanList] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [page, setPage] = React.useState(1);
    // console.log('ppplll', membershipDetail)

    const appId = localStorage.getItem("appId");
    const isC2App = React.useMemo(() => {
        if (typeof appId === "string") {
            return (
                [
                    CONSTANTS.APP.C2_ROWER,
                    CONSTANTS.APP.C2_SKIERG,
                    CONSTANTS.APP.C2_BIKEERG,
                ].indexOf(Number(appId)) !== -1
            );
        }
        return false;
    }, [appId]);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        if (id) {
            setLoading(true);
            handleQueryData({ orgId: id, page, perPage: 10 })
                .then((res) => {
                    if (res.status === 200) {
                        setMembershipDetail(res?.data);
                        if ((res.data.currentMembership.appId === CONSTANTS.APP.MEMBERSHIP_GO && isC2App) || (res.data.currentMembership.appId === CONSTANTS.APP.MEMBERSHIP_C2 && !isC2App)) {
                            history.push(CONSTANTS.NAVIGATION_URL.MEMBERSHIPS)
                        }
                        return;
                    }
                    setMembershipDetail(undefined);
                })
                .catch((err) => {
                    setMembershipDetail(undefined);
                })
                .finally(() => {
                    setLoading(false);
                    window.scrollTo({
                        left: 0,
                        top: 0,
                        behavior: 'smooth',
                    })
                });
        }
    }, [id, page]);

    // const membershipTitle = useMemo(() => {
    //     const plan = planList.find(
    //         (item: any) =>
    //             item.id === membershipDetail?.currentMembership?.planId
    //     );
    //     return plan?.name || "";
    // }, [planList, membershipDetail]);

    const ModelCell = useMemo(() => {
        // (models: string[]) => {
        if (membershipDetail?.currentMembership?.appIds?.length > 0) {
            const appIds = formatAppIds(membershipDetail?.currentMembership?.appIds);
            let res = "";
            for(let i = 0; i < appIds.length; i++) {
                if(i!==appIds.length - 1) {
                    res = res + getAppItem(appIds[i]).value + ', '
                } else {
                    res = res + getAppItem(appIds[i]).value
                }
            }
            return res
        }
    }, [membershipDetail?.currentMembership?.appIds]);

    if (loading) {
        return <AvironLoading />;
    }

    return (
        <Box
            padding={{
                xs: "16px 32px",
                md: 0,
            }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Membership detail | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <GoBack url={location?.pathname} />
            <Stack direction={{ xs: "column-reverse", md: "column" }}>
                    <Box mt="24px">
                        <Grid container>
                            <Grid item md={8}>
                                <PageTitle>Membership detail</PageTitle>
                            </Grid>
                        </Grid>
                    </Box>
                <Paper
                    sx={{
                        padding: "32px 38px",
                        margin: "24px 0",
                        position: "relative",
                    }}
                >
                    <Typography
                        variant="h3"
                        mb="32px"
                        sx={{
                            fontFamily: "Work Sans",
                            fontWeight: 400,
                        }}
                        style={{
                            textTransform: "capitalize",
                        }}
                    >
                        {membershipDetail?.currentMembership?.planName?.toLowerCase()}
                    </Typography>
                    <Grid
                        container
                        display="flex"
                        justifyContent="space-between"
                        spacing={2}
                        direction={{ xs: "column", sm: "column", md: "row" }}
                        paddingBottom={{ xs: "32px" }}
                    >
                        <Grid item xs={12} sm={4} md={2}>
                            <Typography variant="subtitle2">Price</Typography>
                            <Typography variant="body1">
                                <CurrencyNumber
                                    value={get(
                                        membershipDetail,
                                        "currentMembership.price"
                                    )}
                                />
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Typography variant="subtitle2">Status</Typography>
                            <Typography variant="body1">
                                {getStatus(
                                    get(
                                        membershipDetail,
                                        "currentMembership.status"
                                    )?.toString()
                                )?.name || ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Typography variant="subtitle2">
                                Current Period Start
                            </Typography>
                            <Typography variant="body1">
                                {moment
                                    .unix(
                                        get(
                                            membershipDetail,
                                            "currentMembership.startAt"
                                        )
                                    )
                                    .format("MM/DD/YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Typography variant="subtitle2">
                                Current Period End
                            </Typography>
                            <Typography variant="body1">
                                {moment
                                    .unix(
                                        get(
                                            membershipDetail,
                                            "currentMembership.expiredAt"
                                        )
                                    )
                                    .format("MM/DD/YYYY")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Typography variant="subtitle2">Models</Typography>
                            <Typography variant="body1">{ModelCell}</Typography>
                        </Grid>
                    </Grid>
                    {membershipDetail?.receipts ? (
                        <>
                            <Divider
                                sx={{
                                    position: "absolute",
                                    left: 0,
                                    width: "100%",
                                }}
                            />
                            <Box mt="16px">
                                <Typography variant="h4" color="#808080">
                                    Receipts
                                </Typography>
                                <ReceiptsForC2
                                    receipts={membershipDetail?.receipts?.data}
                                    status={get(
                                        membershipDetail,
                                        "currentMembership.status"
                                    )}
                                    planName={membershipDetail?.currentMembership?.planName}
                                />
                            </Box>
                        </>
                    ) : null}
                    {Boolean(membershipDetail?.totalRecords) && (
                        <Pagination
                            count={Math.ceil(
                                membershipDetail?.totalRecords / 10
                            )}
                            size="large"
                            showFirstButton
                            showLastButton
                            page={page}
                            onChange={handleChange}
                            style={{
                                justifyContent: "center",
                                display: "flex",
                                marginTop: 20,
                            }}
                        />
                    )}
                </Paper>
            </Stack>
        </Box>
    );
};

export default MembershipDetailForC2;
