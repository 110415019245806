import React from "react";
import style from "./CompareStats.module.scss";
import CardSimple from "Components/CardSimple";
import CompareTable from "./CompareTable";
import Select from "Components/FormElements/Select";

const CompareStats = ({
    summary,
    handleChangeSelect,
    selectValue,
    timeOptions,
}: any) => {
    return (
        <div className={style["CompareStatsWrapper"]}>
            <CardSimple
                title={
                    <div className={style["titleCardWrapper"] + ' titleCard'}>
                        <div className={style["titleCard"]}>COMPARE STATISTICS</div>
                        <div className={style["select"]}>
                            <Select
                                options={timeOptions.map(
                                    ({ value, name } :any) => ({
                                        name: name,
                                        value: value,
                                    })
                                )}
                                fullWidth
                                value={selectValue}
                                onChange={handleChangeSelect}
                            />
                        </div>
                    </div>
                }
            >
                <CompareTable
                    summaryTable={{ ...summary }}
                    nameHeaderRow={timeOptions[selectValue].headerTable}
                    selected = {timeOptions[selectValue].label}
                />
            </CardSimple>
        </div>
    );
};

export default CompareStats;
