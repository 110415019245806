import React from 'react'
import { Grid, Box, Typography, Paper } from "@mui/material"

const MobileDownload = () => {
    return <Paper>
        <Box padding={"40px"} maxWidth={"900px"}>
            <Grid container display={"flex"} sx={{
                textAlign: {
                    xs: "center",
                    md: "left"
                },
                flexDirection: {
                    xs: "column-reverse",
                    md: "row"
                }
            }}>
                <Grid xs={12} md={7} display={"flex"} alignItems={"center"}>
                    <Box>
                        <Typography variant="h1" component="h1">MANAGE YOUR<br />INTEGRATIONS</Typography>
                        <Typography variant="body1" component="p" fontSize={"16px"} mt="16px">Say goodbye to hassle – manage all your integrations<br/>seamlessly with our app!</Typography>
                        <Box mt={"56px"}>
                            <Grid container gap="24px" justifyContent={{ xs: "center", md: "left" }}>
                                <Grid item>
                                    <a href="https://play.google.com/store/apps/details?id=com.avironactive.mobile&hl=en" target="_blank">
                                        <img src="/images/integrations/googlestore.jpg" alt="Mobile download" width={"120px"} />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="https://apps.apple.com/vn/app/myaviron-companion/id6443441596" target="_blank">
                                        <img src="/images/integrations/appstore.jpg" alt="Mobile download" width={"120px"} />
                                    </a>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mt={"56px"}>
                            <Grid container justifyContent={"space-between"}>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    }
                                }}>
                                    <img src="/images/integrations/googlefit.png" alt="Google Fit"/>
                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    }
                                }}>
                                    <img src="/images/integrations/strava.png" alt="Strava" />
                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    }
                                }}>
                                    <img src="/images/integrations/concept2.png" alt="Concept2" />
                                </Grid>
                                <Grid item sx={{
                                    width: {
                                        xs: "50%",
                                        md: "fit-content"
                                    }
                                }}>
                                    <img src="/images/integrations/fitbit.png" alt="FitBit" />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} md={5}>
                    <img src="/images/integrations/integration-mobile.webp" alt="Mobile download" width={"100%"} />
                </Grid>
            </Grid>
        </Box>
    </Paper >
}

export default MobileDownload;