import React from 'react';
import style from './table.module.scss'
interface tableProps {
  columns: Array<{key:string, title: any, className?:string}>
  rowsData: any,
}
const Table = (props: tableProps) => {
  const {rowsData, columns} = props;
  const renderBody = (rowsData: any, columns: any) => {
    const rowsRender = rowsData.map((row: any, key: any) => {
        return (
            <tr>
                {columns.map((col: any, keyCol: any) => {
                    if (row && row[col.key]) {
                        return (
                            <td className={row[col?.key]?.className}>
                                <div>{row[col?.key]?.value}</div>
                            </td>
                        );
                    } else {
                        return "";
                    }
                })}
            </tr>
        );
    });
    return rowsRender;
  };
  return (
      <div className={style["tableWrapper"]}>
          <table>
              <tr>
                  {columns.map((col:any, key:any) => {
                      return (
                          <th
                              className={col.className}
                              key={key + "header"}
                          >
                              <div>{col.title}</div>
                          </th>
                      );
                  })}
              </tr>
              {renderBody(rowsData, columns)}
          </table>
      </div>
  );
};

export default Table;